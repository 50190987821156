import React from 'react'
import { navigate } from 'gatsby'

class Product extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    navigate('/');
  }

  render() {
    return ''
  }
}

export default Product;